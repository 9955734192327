import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Layout from '../components/layout/layout';
import Container from '../components/common/container';

export default function Terms({ initialData }) {
  const [activeSection, setActiveSection] = useState('');
  const [showBackToTop, setShowBackToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 400);

      const sections = document.querySelectorAll('section[id]');
      sections.forEach(section => {
        const sectionTop = section.offsetTop - 100;
        if (window.scrollY >= sectionTop) {
          const id = section.getAttribute('id');
          setActiveSection(id);
          // Update URL hash without scroll
          const newUrl = `${window.location.pathname}${id ? '#' + id : ''}`;
          window.history.replaceState(null, '', newUrl);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const scrollToHash = () => {
      const hash = window.location.hash.slice(1);
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          const yOffset = -80;
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: 'smooth' });
          setActiveSection(hash);
        }
      }
    };

    scrollToHash();
    window.addEventListener('hashchange', scrollToHash);
    return () => window.removeEventListener('hashchange', scrollToHash);
  }, []);

  const navigationSections = [
    { id: 'agreement', label: 'Agreement to Terms' },
    { id: 'intellectual', label: 'Intellectual Property Rights' },
    { id: 'user-representations', label: 'User Representations' },
    { id: 'registration', label: 'User Registration' },
    { id: 'prohibited', label: 'Prohibited Activities' },
    { id: 'user-content', label: 'User Generated Content' },
    { id: 'license', label: 'Mobile Application License' },
    { id: 'social-media', label: 'Social Media' },
    { id: 'submissions', label: 'Submissions' },
    { id: 'third-party', label: 'Third-Party Content' },
    { id: 'advertisers', label: 'Advertisers' },
    { id: 'site-management', label: 'Site Management' },
    { id: 'privacy', label: 'Privacy Policy' },
    { id: 'term', label: 'Term and Termination' },
    { id: 'modifications', label: 'Modifications' },
    { id: 'law', label: 'Governing Law' },
    { id: 'disputes', label: 'Dispute Resolution' },
    { id: 'corrections', label: 'Corrections' },
    { id: 'disclaimer', label: 'Disclaimer' },
    { id: 'liability', label: 'Limitations of Liability' },
    { id: 'indemnification', label: 'Indemnification' },
    { id: 'user-data', label: 'User Data' },
    { id: 'electronic', label: 'Electronic Communications' },
    { id: 'misc', label: 'Miscellaneous' },
    { id: 'contact', label: 'Contact Us' }
  ];

  const smoothScrollTo = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      const yOffset = -80;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
      setActiveSection(elementId);
      const newUrl = `${window.location.pathname}#${elementId}`;
      window.history.replaceState(null, '', newUrl);
    }
  };

  return (
    <Layout initialData={initialData}>
      {/* Hero Section */}
      <div className="from-primary/5 to-transparent">
        <Container>
          <motion.div 
            className="max-w-3xl mx-auto text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-primary to-primary-hover bg-clip-text text-transparent">
              Terms of Use
            </h1>
            <p className="text-gray-600 text-lg">
              Please read these terms carefully before using our services.
            </p>
          </motion.div>
        </Container>
      </div>

      <Container className="relative">
        <div className="flex flex-col lg:flex-row gap-12 py-12">
          {/* Side Navigation */}
          <div className="lg:w-1/4">
            <div className="sticky top-24 space-y-6 hidden lg:block">
              <nav className="space-y-1">
                {navigationSections.map((section) => (
                  <button
                    key={section.id}
                    onClick={() => smoothScrollTo(section.id)}
                    className={`w-full text-left px-4 py-2 text-sm rounded-lg transition-colors duration-200 hover:bg-primary/5 
                      ${activeSection === section.id ? 'text-primary font-medium bg-primary/5' : 'text-gray-600'}`}
                  >
                    {section.label}
                  </button>
                ))}
              </nav>
            </div>
          </div>

          {/* Main Content */}
          <div className="lg:w-3/4">
            <div className="prose max-w-none">
              {/* Agreement Section */}
              <motion.section
                id="agreement"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Agreement to Terms</h2>
                <div className="space-y-4">
                  <p className="text-gray-600">
                    These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf 
                    of an entity ("you") and WymBee Technologies Limited ("Company," "we," "us," or "our"), concerning your 
                    access to and use of the wymbee.com website as well as any other media form, media channel, mobile website 
                    or mobile application related, linked, or otherwise connected thereto (collectively, the "Site").
                  </p>
                  <div className="p-4 bg-primary/5 rounded-lg">
                    <p className="text-gray-700">
                      You agree that by accessing the Site, you have read, understood, and agree to be bound by all of these Terms of Use.
                    </p>
                  </div>
                </div>
              </motion.section>

              {/* Intellectual Property Rights Section */}
              <motion.section
                id="intellectual"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Intellectual Property Rights</h2>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <p className="text-gray-600 mb-4">
                    Unless otherwise indicated, the Site is our proprietary property and all source code, databases, 
                    functionality, software, website designs, audio, video, text, photographs, and graphics on the Site 
                    (collectively, the "Content") and the trademarks, service marks, and logos contained therein (the "Marks") 
                    are owned or controlled by us or licensed to us.
                  </p>
                  <div className="space-y-4">
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <h3 className="font-medium mb-2">Limited License</h3>
                      <p className="text-gray-600">
                        You are granted a limited license to access and use the Site and to download or print Content solely 
                        for your personal, non-commercial use. You must not modify, reproduce, or distribute any Content.
                      </p>
                    </div>
                  </div>
                </div>
              </motion.section>

              {/* User Representations Section */}
              <motion.section
                id="user-representations"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">User Representations</h2>
                <div className="space-y-6">
                  <div className="p-6 bg-white rounded-lg border border-gray-100">
                    <p className="text-gray-600 mb-4">By using the Site, you represent and warrant that:</p>
                    <ul className="space-y-4">
                      {[
                        "All registration information you submit will be true, accurate, current, and complete",
                        "You will maintain the accuracy of such information",
                        "You have the legal capacity and agree to comply with these Terms of Use",
                        "You are not under the age of 18",
                        "You will not access the Site through automated or non-human means",
                        "You will not use the Site for any illegal or unauthorized purpose",
                        "Your use of the Site will not violate any applicable law or regulation"
                      ].map((item, index) => (
                        <li key={index} className="flex items-start">
                          <svg className="w-5 h-5 text-primary flex-shrink-0 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                          <span className="ml-3 text-gray-600">{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </motion.section>

              {/* Registration Section */}
              <motion.section
                id="registration"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">User Registration</h2>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <p className="text-gray-600 mb-4">
                    You may be required to register with the Site. You agree to keep your password confidential and 
                    will be responsible for all use of your account and password. We reserve the right to remove, 
                    reclaim, or change a username you select if we determine, in our sole discretion, that such 
                    username is inappropriate, obscene, or otherwise objectionable.
                  </p>
                </div>
              </motion.section>

              {/* Prohibited Activities Section */}
              <motion.section
                id="prohibited"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Prohibited Activities</h2>
                <div className="space-y-6">
                  <div className="p-6 bg-white rounded-lg border border-gray-100">
                    <p className="text-gray-600 mb-6">
                      You may not access or use the Site for any purpose other than that for which we make the Site available.
                    </p>
                    
                    <div className="grid gap-6">
                      {[
                        {
                          category: "Security and Access",
                          items: [
                            "Systematically retrieve data or content",
                            "Circumvent security features",
                            "Upload malicious code",
                            "Interfere with site functionality"
                          ]
                        },
                        {
                          category: "Content and Behavior",
                          items: [
                            "Post inappropriate or harmful content",
                            "Impersonate others",
                            "Harass or harm other users",
                            "Spam or send unsolicited messages"
                          ]
                        },
                        {
                          category: "Commercial Activities",
                          items: [
                            "Unauthorized commercial use",
                            "Compete with the Site",
                            "Sell or transfer your profile",
                            "Use buying agents or purchasing agents"
                          ]
                        }
                      ].map((category, index) => (
                        <div key={index} className="p-4 bg-gray-50 rounded-lg">
                          <h3 className="font-medium text-primary mb-3">{category.category}</h3>
                          <ul className="space-y-2">
                            {category.items.map((item, itemIndex) => (
                              <li key={itemIndex} className="flex items-start text-gray-600">
                                <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                                <span className="ml-4">{item}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </motion.section>

              {/* User Generated Content Section */}
              <motion.section
                id="user-content"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">User Generated Content</h2>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <p className="text-gray-600 mb-6">
                    The Site may invite you to chat, contribute to, or participate in blogs, message boards, online forums, 
                    and other functionality, and may provide you with the opportunity to create, submit, post, display, 
                    transmit, perform, publish, distribute, or broadcast content and materials to us or on the Site.
                  </p>
                  
                  <div className="space-y-6">
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <h3 className="font-medium mb-3">Content Standards</h3>
                      <ul className="space-y-3">
                        <li className="flex items-start">
                          <svg className="w-5 h-5 text-primary flex-shrink-0 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                          <span className="ml-3 text-gray-600">Must not violate any applicable laws or rights</span>
                        </li>
                        <li className="flex items-start">
                          <svg className="w-5 h-5 text-primary flex-shrink-0 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                          <span className="ml-3 text-gray-600">Must not be defamatory, obscene, or offensive</span>
                        </li>
                        <li className="flex items-start">
                          <svg className="w-5 h-5 text-primary flex-shrink-0 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                          <span className="ml-3 text-gray-600">Must not infringe on intellectual property rights</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </motion.section>

              {/* Mobile Application License Section */}
              <motion.section
                id="license"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Mobile Application License</h2>
                <div className="space-y-6">
                  <div className="p-6 bg-white rounded-lg border border-gray-100">
                    <h3 className="text-xl font-semibold text-primary mb-4">Use License</h3>
                    <p className="text-gray-600 mb-4">
                      We grant you a revocable, non-exclusive, non-transferable, limited right to install and use the 
                      mobile application on wireless electronic devices owned or controlled by you, and to access and use 
                      the mobile application on such devices strictly in accordance with these Terms of Use.
                    </p>
                    
                    <div className="mt-6 p-4 bg-gray-50 rounded-lg">
                      <h4 className="font-medium mb-3">Restrictions</h4>
                      <ul className="space-y-2">
                        {[
                          "No decompiling or reverse engineering",
                          "No modifications or derivative works",
                          "No unauthorized distribution",
                          "No commercial use or resale",
                          "No multiple device access at the same time",
                          "No use of automated systems or scripts"
                        ].map((item, index) => (
                          <li key={index} className="flex items-start text-gray-600">
                            <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                            <span className="ml-4">{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </motion.section>
              
              {/* Social Media Section */}
              <motion.section
                id="social-media"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Social Media</h2>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <p className="text-gray-600 mb-6">
                    As part of the functionality of the Site, you may link your account with online accounts you have with 
                    third-party service providers (each such account, a "Third-Party Account").
                  </p>
                  <div className="space-y-6">
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <h3 className="font-medium text-primary mb-3">Account Linking</h3>
                      <p className="text-gray-600 mb-4">You can link your account by either:</p>
                      <ul className="space-y-2">
                        <li className="flex items-start text-gray-600">
                          <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                          <span className="ml-4">Providing your Third-Party Account login information</span>
                        </li>
                        <li className="flex items-start text-gray-600">
                          <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                          <span className="ml-4">Allowing us to access your Third-Party Account</span>
                        </li>
                      </ul>
                    </div>
                    <div className="p-4 bg-primary/5 rounded-lg">
                      <p className="text-gray-700">
                        <strong>Note:</strong> Your relationship with third-party service providers is governed solely by your 
                        agreement(s) with such providers.
                      </p>
                    </div>
                  </div>
                </div>
              </motion.section>

              {/* Submissions Section */}
              <motion.section
                id="submissions"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Submissions</h2>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <p className="text-gray-600 mb-6">
                    You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information 
                    regarding the Site ("Submissions") provided by you to us are non-confidential and shall become our sole property.
                  </p>
                  <div className="grid gap-4">
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <h3 className="font-medium text-primary mb-3">Our Rights</h3>
                      <ul className="space-y-2">
                        <li className="flex items-start text-gray-600">
                          <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                          <span className="ml-4">Exclusive ownership of all intellectual property rights</span>
                        </li>
                        <li className="flex items-start text-gray-600">
                          <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                          <span className="ml-4">Unrestricted use and dissemination for any purpose</span>
                        </li>
                        <li className="flex items-start text-gray-600">
                          <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                          <span className="ml-4">No acknowledgment or compensation required</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </motion.section>

              {/* Third-Party Content Section */}
              <motion.section
                id="third-party"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Third-Party Content</h2>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <p className="text-gray-600 mb-6">
                    The Site may contain links to other websites ("Third-Party Websites") as well as articles, photographs, 
                    text, graphics, pictures, designs, music, sound, video, information, applications, software, and other 
                    content or items belonging to or originating from third parties ("Third-Party Content").
                  </p>
                  <div className="space-y-6">
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <h3 className="font-medium text-primary mb-3">Disclaimer</h3>
                      <p className="text-gray-600">
                        Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for 
                        accuracy, appropriateness, or completeness by us.
                      </p>
                    </div>
                    <div className="p-4 bg-primary/5 rounded-lg">
                      <h3 className="font-medium mb-3">Your Responsibility</h3>
                      <p className="text-gray-600">
                        You access Third-Party Websites and use Third-Party Content at your own risk.
                      </p>
                    </div>
                  </div>
                </div>
              </motion.section>

              {/* Advertisers Section */}
              <motion.section
                id="advertisers"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Advertisers</h2>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <p className="text-gray-600 mb-6">
                    We allow advertisers to display their advertisements and other information in certain areas of the Site. 
                    We are not responsible for any such advertisements.
                  </p>
                  <div className="p-4 bg-gray-50 rounded-lg">
                    <h3 className="font-medium text-primary mb-3">Advertiser Responsibilities</h3>
                    <ul className="space-y-2">
                      <li className="flex items-start text-gray-600">
                        <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                        <span className="ml-4">Take full responsibility for any advertisements placed</span>
                      </li>
                      <li className="flex items-start text-gray-600">
                        <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                        <span className="ml-4">Warrant possession of necessary rights and authority</span>
                      </li>
                      <li className="flex items-start text-gray-600">
                        <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                        <span className="ml-4">Comply with all applicable laws and regulations</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </motion.section>

              {/* Site Management Section */}
              <motion.section
                id="site-management"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Site Management</h2>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <p className="text-gray-600 mb-6">
                    We reserve the right, but not the obligation, to monitor and manage the Site for violations of these Terms of Use.
                  </p>
                  <div className="space-y-4">
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <h3 className="font-medium text-primary mb-3">Our Rights Include</h3>
                      <ul className="space-y-2">
                        {[
                          "Monitor the Site for violations",
                          "Take appropriate legal action against violators",
                          "Refuse, restrict, or limit access",
                          "Remove excessive or burdensome content",
                          "Manage the Site to protect our rights and property"
                        ].map((item, index) => (
                          <li key={index} className="flex items-start text-gray-600">
                            <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                            <span className="ml-4">{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </motion.section>

              {/* Privacy Policy Section */}
              <motion.section
                id="privacy"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Privacy Policy</h2>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <p className="text-gray-600 mb-6">
                    We care about data privacy and security. Please review our Privacy Policy: wymbee.com/privacy. 
                    By using the Site, you agree to be bound by our Privacy Policy, which is incorporated into these Terms of Use.
                  </p>
                  <div className="p-4 bg-primary/5 rounded-lg">
                    <h3 className="font-medium mb-3">Data Transfer Notice</h3>
                    <p className="text-gray-600">
                      Please be advised the Site is hosted in Kenya. If you access the Site from any other region of the world 
                      with laws governing data collection and use that may differ from Kenya law, you agree to the transfer of your 
                      data to Kenya and processing according to Kenya law.
                    </p>
                  </div>
                </div>
              </motion.section>

              {/* Term and Termination Section */}
              <motion.section
                id="term"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Term and Termination</h2>
                <div className="space-y-6">
                  <div className="p-6 bg-white rounded-lg border border-gray-100">
                    <p className="text-gray-600 mb-4">
                      These Terms of Use shall remain in full force and effect while you use the Site.
                    </p>
                    <div className="space-y-4">
                      <div className="p-4 bg-gray-50 rounded-lg">
                        <h3 className="font-medium text-primary mb-3">Termination Rights</h3>
                        <ul className="space-y-3">
                          <li className="flex items-start text-gray-600">
                            <svg className="w-5 h-5 text-primary flex-shrink-0 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                            </svg>
                            <span className="ml-3">We reserve the right to terminate or suspend your access without notice</span>
                          </li>
                          <li className="flex items-start text-gray-600">
                            <svg className="w-5 h-5 text-primary flex-shrink-0 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                            </svg>
                            <span className="ml-3">Account termination may occur for any violation of these Terms</span>
                          </li>
                          <li className="flex items-start text-gray-600">
                            <svg className="w-5 h-5 text-primary flex-shrink-0 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                            </svg>
                            <span className="ml-3">Creation of new accounts after termination is prohibited</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.section>

              {/* Modifications Section */}
              <motion.section
                id="modifications"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Modifications and Interruptions</h2>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <div className="space-y-6">
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <h3 className="font-medium text-primary mb-3">Site Changes</h3>
                      <p className="text-gray-600">
                        We reserve the right to change, modify, or remove the contents of the Site at any time without notice.
                        However, we have no obligation to update any information on our Site.
                      </p>
                    </div>
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <h3 className="font-medium text-primary mb-3">Service Interruptions</h3>
                      <p className="text-gray-600">
                        We cannot guarantee the Site will be available at all times. We may experience hardware, software, 
                        or other problems or need to perform maintenance related to the Site, resulting in interruptions, 
                        delays, or errors.
                      </p>
                    </div>
                  </div>
                </div>
              </motion.section>

              {/* Governing Law Section */}
              <motion.section
                id="law"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Governing Law</h2>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <p className="text-gray-600">
                    These Terms shall be governed by and defined following the laws of Kenya. WymBee Technologies Limited 
                    and yourself irrevocably consent that the courts of Kenya shall have exclusive jurisdiction to resolve 
                    any dispute which may arise in connection with these terms.
                  </p>
                </div>
              </motion.section>

              {/* Dispute Resolution Section */}
              <motion.section
                id="disputes"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Dispute Resolution</h2>
                <div className="space-y-6">
                  <div className="p-6 bg-white rounded-lg border border-gray-100">
                    <h3 className="text-xl font-semibold text-primary mb-4">Informal Negotiations</h3>
                    <p className="text-gray-600 mb-4">
                      To expedite resolution and control the cost of any dispute, you and we agree to first attempt to 
                      negotiate any dispute informally for at least 100 days before initiating arbitration.
                    </p>
                  </div>
                  <div className="p-6 bg-white rounded-lg border border-gray-100">
                    <h3 className="text-xl font-semibold text-primary mb-4">Binding Arbitration</h3>
                    <p className="text-gray-600 mb-4">
                      If informal negotiations fail, any dispute shall be referred to and finally resolved by arbitration 
                      in Kenya under local arbitration laws.
                    </p>
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <ul className="space-y-2">
                        <li className="flex items-start text-gray-600">
                          <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                          <span className="ml-4">Arbitration shall be conducted in Kiswahili</span>
                        </li>
                        <li className="flex items-start text-gray-600">
                          <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                          <span className="ml-4">The number of arbitrators shall be three</span>
                        </li>
                        <li className="flex items-start text-gray-600">
                          <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                          <span className="ml-4">The seat of arbitration shall be Kisumu, Kenya</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </motion.section>

              {/* Corrections Section */}
              <motion.section
                id="corrections"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Corrections</h2>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <p className="text-gray-600">
                    There may be information on the Site that contains typographical errors, inaccuracies, or omissions. 
                    We reserve the right to correct any errors, inaccuracies, or omissions, and to change or update the 
                    information on the Site at any time, without prior notice.
                  </p>
                </div>
              </motion.section>

              {/* Disclaimer Section */}
              <motion.section
                id="disclaimer"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Disclaimer</h2>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <p className="text-gray-600 mb-6 uppercase font-medium">
                    The site is provided on an as-is and as-available basis
                  </p>
                  <div className="space-y-4">
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <h3 className="font-medium text-primary mb-3">We Make No Warranties Regarding</h3>
                      <ul className="space-y-2">
                        {[
                          "The accuracy or completeness of site content",
                          "The security of your personal information",
                          "Uninterrupted access to the site",
                          "Results obtained from using the site",
                          "The correction of any defects or errors",
                          "The safety, reliability, or availability of third-party products"
                        ].map((item, index) => (
                          <li key={index} className="flex items-start text-gray-600">
                            <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                            <span className="ml-4">{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </motion.section>

              {/* Limitations of Liability Section */}
              <motion.section
                id="liability"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Limitations of Liability</h2>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <p className="text-gray-600 mb-6">
                    In no event will we be liable for any direct, indirect, consequential, exemplary, incidental, special, 
                    or punitive damages, including lost profit, lost revenue, loss of data, or other damages arising from 
                    your use of the site.
                  </p>
                  <div className="p-4 bg-primary/5 rounded-lg">
                    <p className="text-gray-700">
                      <strong>Liability Limit:</strong> Our liability shall be limited to the maximum extent permitted by law, 
                      and shall not exceed the amount paid by you, if any, for using our services.
                    </p>
                  </div>
                </div>
              </motion.section>

              {/* Indemnification Section */}
              <motion.section
                id="indemnification"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Indemnification</h2>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <p className="text-gray-600 mb-6">
                    You agree to defend, indemnify, and hold us harmless from and against any claims, liabilities, damages, 
                    judgments, awards, losses, costs, expenses, or fees arising out of or relating to your violation of these 
                    Terms of Use or your use of the Site.
                  </p>
                </div>
              </motion.section>

              {/* User Data Section */}
              <motion.section
                id="user-data"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">User Data</h2>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <p className="text-gray-600 mb-6">
                    We will maintain certain data that you transmit to the Site for the purpose of managing the performance 
                    of the Site, as well as data relating to your use of the Site.
                  </p>
                  <div className="p-4 bg-gray-50 rounded-lg">
                    <h3 className="font-medium text-primary mb-3">Your Responsibilities</h3>
                    <p className="text-gray-600">
                      Although we perform regular routine backups of data, you are solely responsible for all data that you 
                      transmit or that relates to any activity you have undertaken using the Site.
                    </p>
                  </div>
                </div>
              </motion.section>

              {/* Electronic Communications Section */}
              <motion.section
                id="electronic"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Electronic Communications</h2>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <p className="text-gray-600 mb-6">
                    Visiting the Site, sending us emails, and completing online forms constitute electronic communications. 
                    You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, 
                    and other communications we provide to you electronically satisfy any legal requirement that such 
                    communication be in writing.
                  </p>
                </div>
              </motion.section>

              {/* Miscellaneous Section */}
              <motion.section 
                id="misc" 
                initial={{ opacity: 0 }} 
                whileInView={{ opacity: 1 }} 
                viewport={{ once: true }} 
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Miscellaneous</h2>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <div className="space-y-6">
                    <div className="p-4 bg-gray-50 rounded-lg">
                      <h3 className="font-medium text-primary mb-3">General Provisions</h3>
                      <ul className="space-y-3">
                        {[
                          "These Terms constitute the entire agreement between you and us regarding the Site",
                          "Our failure to exercise any right shall not constitute a waiver",
                          "These Terms operate to the fullest extent permissible by law",
                          "We may assign our rights and obligations to others at any time",
                          "If any provision is found to be unenforceable, the remaining provisions remain in effect",
                          "There is no joint venture or partnership created by these Terms"
                        ].map((item, index) => (
                          <li key={index} className="flex items-start text-gray-600">
                            <span className="w-2 h-2 mt-2 rounded-full bg-primary flex-shrink-0"></span>
                            <span className="ml-4">{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </motion.section>

              {/* Contact Us Section */}
              <motion.section 
                id="contact" 
                initial={{ opacity: 0 }} 
                whileInView={{ opacity: 1 }} 
                viewport={{ once: true }} 
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Contact Us</h2>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <p className="text-gray-600 mb-6">
                    In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site, 
                    please contact us at:
                  </p>
                  <div className="space-y-4">
                    <div className="flex flex-col space-y-2">
                      <h3 className="font-medium text-primary">WymBee Technologies Limited</h3>
                      <p className="text-gray-600">Kisumu - Kakamega Road</p>
                      <p className="text-gray-600">Kisumu City, Kisumu County 288-40100</p>
                      <p className="text-gray-600">Kenya</p>
                    </div>
                    <div className="flex items-center space-x-2 text-gray-600">
                      <span className="font-medium">Phone:</span>
                      <a href="tel:+254703867693" className="hover:text-primary transition-colors">(+254)703867693</a>
                    </div>
                    <div className="flex items-center space-x-2 text-gray-600">
                      <span className="font-medium">Email:</span>
                      <a href="mailto:info@wymbee.com" className="hover:text-primary transition-colors">info@wymbee.com</a>
                    </div>
                  </div>
                </div>
              </motion.section>
              
              {/* Last Updated Section */}
              <motion.div 
                className="text-center border-t border-gray-200 pt-8"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
              >
                <p className="text-gray-500">
                  Last updated: {initialData?.lastUpdated || 'November 27, 2024'}
                </p>
                <p className="text-gray-500 mt-2">
                  If you have any questions about our Terms of Use, please{' '}
                  <a href="/help" className="text-primary hover:text-primary-hover underline">
                    contact us
                  </a>.
                </p>
              </motion.div>
              
              {/* Contact Section */}
              <motion.section
                id="contact"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                className="mb-16"
              >
                <h2 className="text-2xl font-bold mb-6">Contact Us</h2>
                <div className="p-6 bg-white rounded-lg border border-gray-100">
                  <p className="text-gray-600 mb-6">
                    In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site, 
                    please contact us at:
                  </p>
                  <div className="space-y-4">
                    <div className="flex items-center space-x-2 text-gray-600">
                      <span className="font-medium">WymBee Technologies Limited</span>
                    </div>
                    <div className="flex items-center space-x-2 text-gray-600">
                      <span className="font-medium">Phone:</span>
                      <span>(+254)703867693</span>
                    </div>
                    <div className="flex items-center space-x-2 text-gray-600">
                      <span className="font-medium">Email:</span>
                      <span>info@wymbee.com</span>
                    </div>
                  </div>
                </div>
              </motion.section>
            </div>
          </div>
        </div>
      </Container>

      {/* Back to Top Button */}
      <motion.button
        initial={{ opacity: 0 }}
        animate={{ opacity: showBackToTop ? 1 : 0 }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        className="fixed bottom-24 right-6 bg-primary text-white p-3 rounded-full shadow-lg hover:bg-primary-hover transition-colors duration-200"
        aria-label="Back to top"
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
        </svg>
      </motion.button>
    </Layout>
  );
}